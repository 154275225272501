<template>
  <div>
    <feed-back-view
      :interview-id.sync="interviewId"
      :user-data.sync="dataLocal"
      :is-view-interview-sidebar-active.sync="isViewInterviewSidebarActive"
    />
    <li
      class="timeline-item"
      :class="[
        `timeline-variant-${variant}`,
        fillBorder ? `timeline-item-fill-border-${variant}` : null,
      ]"
    >
      <div v-if="!icon" class="timeline-item-point" />
      <div
        v-else
        class="
          timeline-item-icon
          d-flex
          align-items-center
          justify-content-center
          rounded-circle
        "
      >
        <feather-icon :icon="icon" />
      </div>

      <slot>
        <div
          class="
            row
            flex-sm-row flex-column flex-wrap
            justify-content-between
            mb-1 mb-sm-0
          "
        >
          <h6 class="col-4">
            {{ title }}
            <span v-if="item.edited_at" class="badge badge-secondary"
              >Edited : {{ item.edited_at }}</span
            >
          </h6>
          <div class="col-4 text-center">
            <b-badge
              v-if="option.text"
              pill
              :variant="option.color"
              class="align-items-center justify-content-between"
            >
              <feather-icon
                class="cursor-pointer"
                :icon="option.icon"
                size="16"
              />
              <span style="padding-left: 3px">
                {{ option.text }}
              </span>
            </b-badge>
          </div>
          <div class="col-4 text-right">
            <b-button
              v-if="option.text"
              variant="outline-info"
              size="sm"
              @click="viewFeedback(item)"
            >
              <feather-icon icon="EyeIcon" class="mr-25" />
              <span>View Feedback</span>
            </b-button>
          </div>
        </div>

        <p class="mb-0" v-text="subtitle" />
        <small
          class="timeline-item-time text-nowrap text-muted"
          v-text="time"
        />
      </slot>
    </li>
  </div>
</template>


<script>
import FeedBackView from "@/views/interviews/assigned/list/FeedBackView.vue";
export default {
  components: {
    FeedBackView,
  },
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    time: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    fillBorder: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  created() {
    this.dataLocal = this.item;
    switch (this.item.overall_rating) {
      case "Strongly Hire":
        this.option = this.options[0];
        break;
      case "Hire":
        this.option = this.options[1];
        break;
      case "Hold":
        this.option = this.options[2];
        break;
      case "No Hire":
        this.option = this.options[3];
        break;
      case "Strong No Hire":
        this.option = this.options[4];
        break;
    }
  },
  watch: {
    item: function (val) {
      this.refresh();
    },
  },
  data() {
    return {
      interviewId: "",
      isViewInterviewSidebarActive: false,
      option: {
        color: "",
        icon: "",
        text: "",
        value: "",
      },
      options: [
        {
          text: "Strongly Hire",
          value: "Strongly Hire",
          color: "success",
          icon: "ThumbsUpIcon",
        },
        {
          text: "Hire",
          value: "Hire",
          color: "success",
          icon: "ThumbsUpIcon",
        },
        {
          text: "Hold",
          value: "Hold",
          color: "primary",
          icon: "PauseIcon",
        },
        {
          text: "No Hire",
          value: "No Hire",
          color: "danger",
          icon: "ThumbsDownIcon",
        },
        {
          text: "Strong No Hire",
          value: "Strong No Hire",
          color: "danger",
          icon: "ThumbsDownIcon",
        },
      ],
      dataLocal: [],
    };
  },
  methods: {
    viewFeedback(data) {
      const self = this;
      this.interviewId = null;
      self.isViewInterviewSidebarActive = true;
    },
    refresh() {
      switch (this.item.overall_rating) {
        case "Strongly Hire":
          this.option = this.options[0];
          break;
        case "Hire":
          this.option = this.options[1];
          break;
        case "Hold":
          this.option = this.options[2];
          break;
        case "No Hire":
          this.option = this.options[3];
          break;
        case "Strong No Hire":
          this.option = this.options[4];
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include"; // Bootstrap includes
@import "~@core/scss/base/components/include"; // Components includes

// Color palettes
@import "~@core/scss/base/core/colors/palette-variables.scss";

$timeline-border-color: $border-color;

/* Generate:
*  Apply background color to dot
*/
@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    @if $color_type== "base" {
      .timeline-variant-#{$color_name} {
        &.timeline-item-fill-border-#{$color_name} {
          border-color: $color_value !important;
          &:last-of-type {
            &:after {
              background: linear-gradient($color_value, $white);
            }
          }
        }
        .timeline-item-point {
          background-color: $color_value;

          &:before {
            background-color: rgba($color_value, 0.12);
          }
        }

        .timeline-item-icon {
          color: $color_value;
          border: 1px solid $color_value;
        }
      }
    }
  }
}

.timeline-item {
  padding-left: 2.5rem;
  position: relative;
  &:not(:last-of-type) {
    padding-bottom: 2rem;
    border-left: 1px solid $timeline-border-color;
  }

  // This gives shade to last timeline-item but isn't that revolutionary
  &:last-of-type {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background: linear-gradient($timeline-border-color, $white);
    }
  }

  .timeline-item-point {
    position: absolute;
    left: -6px;
    width: 12px;
    height: 12px;
    top: 0;
    border-radius: 50%;
    z-index: 1;

    &:before {
      content: "";
      z-index: 1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: 0;
    }
  }

  .timeline-item-icon {
    position: absolute;
    left: -12px;
    top: 0;
    width: 24px;
    height: 24px;
    background-color: $white;
    z-index: 1;
  }
}

// *===============================================---*
// *--------- Dark Layout ---------------------------------------*
// *===============================================---*

.dark-layout {
  .timeline-item {
    &:last-of-type {
      &:after {
        background: linear-gradient(
          $theme-dark-border-color,
          $theme-dark-card-bg
        );
      }
    }
    &:not(:last-of-type) {
      border-left-color: $theme-dark-border-color;
    }

    .timeline-item-icon {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
